import {Container, Form, Nav, Navbar, Offcanvas} from "react-bootstrap";
import DatePicker from "react-date-picker";
import {Paths} from "../enums/Paths";
import {useCallback, useRef} from "react";

/**
 * Nagłówek witryny
 * @param {function(string)} navigate
 * @param {Date} selectedDate
 * @param {function(Date)} setSelectedDate
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header({navigate, selectedDate, setSelectedDate}) {
    const menuItems = [
        ["Rozkład jazdy", Paths.STATION_LIST],
        ["Park taborowy", Paths.TABOR],
        ["Informacje o projekcie", Paths.ABOUT],
        ["Polityka prywatności", Paths.PRIVACY],
    ]

    const offcanvasRef = useRef();
    const hideOffcanvas = useCallback(() => {
        if (!!offcanvasRef.current.backdrop)
            offcanvasRef.current.backdrop.click();
    }, [offcanvasRef]);

    return (
        <header className={"sticky-top"}>
            <Navbar variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href={"/"}>KolMaz</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Offcanvas id="navbar-menu" placement={"end"} ref={offcanvasRef} className={"text-bg-dark"}>
                        <Offcanvas.Header closeButton={true} closeVariant={"white"}/>
                        <Offcanvas.Body>
                            <Nav className="me-auto">
                                {
                                    menuItems.map(i => (
                                        <Nav.Link
                                            key={i[0]}
                                            onClick={() => {
                                                navigate(i[1]);
                                                hideOffcanvas();
                                            }}
                                        >
                                            {i[0]}
                                        </Nav.Link>
                                    ))
                                }
                                <Nav.Link href={"https://mazowieckie.com.pl"} active={false}
                                          onClick={() => hideOffcanvas()}>
                                    Koleje Mazowieckie
                                </Nav.Link>
                            </Nav>
                            <Form inline="true">
                                <DatePicker
                                    value={selectedDate}
                                    onChange={(date) => {
                                        // Przy zerowaniu wartości ustawiamy aktualną datę
                                        if (date === null)
                                            date = new Date();
                                        setSelectedDate(date);
                                    }}
                                    className="form-control"
                                    required={true}
                                />
                            </Form>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </header>
    );
}
