/**
 * Obsługuje błędy zgłoszone przez API
 * @param {AxiosError} error
 * @param {function(string)} navigate
 * @param {function(string)} setErrorText
 */
export default function apiErrorHandler(error, navigate, setErrorText) {
    if (error.response) {
        // Błąd zwrócony przez API
        setErrorText(error.response.data.message);
    } else {
        // Błędy zwrócone przez klienta
        if (!error.isAxiosError) {
            setErrorText(error.message);
            console.error(error);
        } else {
            switch (error.code) {
                case "ERR_NETWORK":
                    setErrorText("Błąd połączenia z serwerem API - sprawdź połączenie sieciowe");
                    break;
                case "ERR_BAD_RESPONSE":
                    setErrorText("Błąd analizy odpowiedzi z serwera API");
                    console.log(error.response);
                    break;
                case"ETIMEDOUT":
                case "ECONNABORTED":
                    setErrorText("Błąd połączenia z serwerem API - timeout");
                    break;
                default:
                    setErrorText("Błąd połączenia z serwerem API");
                    console.error(error);
            }
        }
    }
}
