function withArg(path, arg) {
    return path + (arg === null ? '' : `/${arg}`);
}

export const Paths = {
    STATION_LIST: "/station",
    STATION: (id) => withArg('/station', id),
    ABOUT: "/about",
    PRIVACY: "/polityka",
    TABOR: "/tabor",
}
