import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './App.css';

import {useMemo, useState} from "react";
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Search from "./pages/Search";
import Station from "./pages/Station";
import {Paths} from "./enums/Paths";
import About from "./pages/About";
import ErrorBanner from "./elements/ErrorBanner";
import dateToString from "./helpers/dateToString";
import Privacy from "./pages/Privacy";
import ChangeModal from "./elements/ChangeModal";
import SpisTaboru from "./pages/SpisTaboru";


function App() {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [errorBannerText, setErrorBannerText] = useState("");

    const selectedDateString = useMemo(() => dateToString(selectedDate), [selectedDate]);

    return (
        <div className="App">
            <Header navigate={navigate} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
            <main className={"pt-4"}>
                <ChangeModal/>
                <ErrorBanner errorText={errorBannerText}/>
                <Routes>
                    <Route path={"/"} element={<Navigate to={Paths.STATION_LIST}/>}/>
                    <Route path={Paths.STATION_LIST} element={
                        <Search
                            navigate={navigate}
                            setErrorBannerText={setErrorBannerText}
                        />}
                    />
                    <Route path={Paths.STATION(':id')} element={
                        <Station
                            navigate={navigate}
                            setErrorBannerText={setErrorBannerText}
                            selectedDate={selectedDateString}
                        />}
                    />
                    <Route path={Paths.ABOUT} element={<About/>}/>
                    <Route path={Paths.PRIVACY} element={<Privacy/>}/>
                    <Route path={Paths.TABOR} element={<SpisTaboru/>}/>
                    <Route path={"*"} element={<Navigate to={"/"}/>}/>
                </Routes>
            </main>
            <Footer/>
        </div>
    );
}

export default App;
