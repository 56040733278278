import {FaRegSnowflake} from "react-icons/fa";

/**
 * Ikona klimatyzacji
 * @param {boolean} is Definiuje czy klimatyzacja jest obecna
 * @constructor
 */
export default function Klimatyzacja({is = true}) {
    return <span className={is ? "text-primary" : "text-danger"}><FaRegSnowflake/></span>
}
