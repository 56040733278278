import axios from "axios";

/**
 * @typedef departuresAPI
 *
 * @property {stationsAPI} station Informacje o stacji
 * @property {departureDataAPI[]} departures Lista kursów na stacji (chronologicznie wg odjazdów)
 */

/**
 * @typedef departureDataAPI
 *
 * @property {string} trip_id ID pociągu
 * @property {string} nr_poc Numer i nazwa pociągu
 * @property {string} linia Numer linii
 * @property {string} kierunek Kierunek jazdy
 * @property {string} przyjazd Godzina przyjazdu
 * @property {string} odjazd Godzina odjazdu
 * @property {string} peron Numer peronu
 * @property {boolean} isBUS Definiuje czy połączenie jest realizowane autobusem
 * @property {null|taborDataAPI} tabor Typ taboru (opcjonalnie)
 */

/**
 * @typedef taborDataAPI
 *
 * @property {string} pojazd Pojazd obsługujący połączenie
 * @property {string} ilosc Liczba pojazdów w zestawieniu
 */

/**
 * Pobiera listę odjazdów ze stacji w danym dniu
 * @param {string} id
 * @param {string} data
 * @returns {Promise<departuresAPI>}
 */
export default function getDepartures(id, data) {
    return axios.get(`/api/departures/${id}/${data}`)
        .then(resp => resp.data);
}
