import isGeolocationAvalable from "./isGeolocationAvailable";
import defaultPosition from "./defaultPosition";

/**
 * Procedura postępowania w przypadku braku możliwości pobrania lokalizacji
 * @param {string} msg Komunikat błędu
 * @param {function(CoordinateObject)} setPosition
 */
function noLocation(msg, setPosition) {
    console.warn(`Błąd pobierania lokalizacji użytkownika: ${msg}`);
    setPosition(defaultPosition());
}

/**
 * Pobiera aktualną pozycję użytkownika i przekazuje do funkcji wynikowej
 * @param {function(CoordinateObject)} setPosition
 */
export default function getPosition(setPosition) {
    if (!isGeolocationAvalable())
        return noLocation("Usługi lokalizacyjne są niedostępne", setPosition);
    return navigator.geolocation.getCurrentPosition(
        (position) => {
            setPosition({lon: position.coords.longitude, lat: position.coords.latitude})
        },
        (err) => {
            noLocation(err.message, setPosition);
        },
    )
}
