/**
 * Zwraca datę w formie ciągu znakowego
 * @param {Date} data
 * @returns {string}
 */
export default function dateToString(data) {
    const
        year = data.getFullYear().toString(),
        month = data.getMonth() < 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1,
        day = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate();
    return `${year}${month}${day}`;
}
