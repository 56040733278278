import {Spinner, Stack} from "react-bootstrap";

/**
 * Spinner ładowania danych
 * @param {string} text Tekst informujący o ładowaniu danych (opcjonalnie)
 * @returns {JSX.Element}
 */
export default function LoadingSpinner({text = "Ładowanie danych..."}) {
    return <Stack direction={"vertical"} className={"mx-auto"}>
        <Spinner className={"mx-auto"}/>
        <span className={"mx-auto"}>{text}</span>
    </Stack>
}
