import changelogJSON from "../CHANGELOG.json";
import {useCallback, useEffect, useMemo, useState} from "react";

/**
 * Custom hook do zarządzania listą zmian wprowadzonych w aplikacji
 * @param {string} version Numer aktualnej wersji projektu (pusty, jeżeli funkcja ma zwrócić pełną listę zmian)
 * @returns {[Object<string,string[]>, function(string)]}
 */
export default function useChangelog(version = "") {
    const [changelog, setChangelog] = useState({});

    useEffect(() => {
        updateChangelog(version);
    }, []);

    const updateChangelog = useCallback((newVersion) => {
        let toReturn = {...changelog};
        let isLater = newVersion === "" || newVersion === "1.0.0";
        Object.keys(changelogJSON).forEach((v) => {
            if (isLater)
                toReturn[v] = changelogJSON[v];
            else
                isLater = v === newVersion;
        });
        setChangelog(toReturn);
    }, [changelog, setChangelog]);

    return [changelog, updateChangelog];
}
