import {useLocalStorage} from "usehooks-ts";
import {useCallback} from "react";

/**
 * Custom hook do zarządzania listą ulubionych stacji.
 * @returns {[Set<string|number>, (item: string|number) => void, () => void]}
 * Zwraca zestaw ulubionych stacji, funkcję do zmiany statusu stacji oraz funkcję do usunięcia z pamięci.
 */
export default function useFavouriteStationsStorage() {
    const localStorageOptions = {
        deserializer: (value) => value ? new Set(JSON.parse(value)) : new Set(),
        serializer: (value) => JSON.stringify(Array.from(value)),
    }
    const [storage, setStorage, removeStorage] = useLocalStorage("favouriteStations", new Set(), localStorageOptions);

    const changeStateItem = useCallback((item) => {
        const newList = new Set(storage);
        if (newList.has(item))
            newList.delete(item);
        else
            newList.add(item);
        setStorage(newList);
    }, [storage, setStorage]);

    return [storage, changeStateItem, removeStorage];
}
