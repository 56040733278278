import axios from "axios";

/**
 * @typedef trainAPI
 *
 * @property {trainTripAPI} trip Szczegóły pociągu
 * @property {trainDeparturesAPI[]} departures Trasa pociągu
 */

/**
 * @typedef trainTripAPI
 *
 * @property {string} nr_poc Numer i nazwa pociągu
 * @property {string} linia Numer linii
 * @property {string} kierunek Kierunek jazdy
 * @property {boolean} isBUS Definiuje czy połączenie realizowane autobusem
 */

/**
 * @typedef trainDeparturesAPI
 *
 * @property {string} station_id ID stacji pośredniej
 * @property {string} station_name Nazwa stacji pośredniej (opcjonalnie z numerem peronu)
 * @property {string} przyjazd Godzina przyjazdu
 * @property {string} odjazd Godzina odjazdu
 */

/**
 * Pobiera trasę pociągu
 * @param {string} id
 * @returns {Promise<trainAPI>}
 */
export default function getTrain(id) {
    return axios.get(`/api/train/${id}`)
        .then(resp => resp.data);
}
