import {Button, Col, Container, FloatingLabel, Form, InputGroup, ListGroup, Row} from "react-bootstrap";
import {useEffect, useMemo, useState} from "react";
import compareStrings from "../helpers/includeStrings";
import getStations from "../apiCalls/getStations";
import apiErrorHandler from "../helpers/apiErrorHandler";
import LoadingSpinner from "../elements/LoadingSpinner";
import logo from "../media/logo.png";
import Locate from "../elements/icons/Locate";
import defaultPosition from "../helpers/geolocation/defaultPosition";
import getPosition from "../helpers/geolocation/getPosition";
import getNearestStations from "../helpers/geolocation/getNearestStations";
import StationListElement from "../elements/StationListElement";
import useFavouriteStationsStorage from "../helpers/useFavouriteStationsStorage";

/**
 *
 * @param {function(string)} navigate
 * @param {function(string)} setErrorBannerText
 * @returns {JSX.Element}
 */
export default function Search({navigate, setErrorBannerText}) {
    const [stations, setStations] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [userPosition, setUserPosition] = useState(defaultPosition());
    const [favouriteStations] = useFavouriteStationsStorage();
    const favouriteStationsList = useMemo(
        () => stations.filter(s => favouriteStations.has(s.stop_id)),
        [favouriteStations, stations]);

    const [userInput, setUserInput] = useState("");
    const [stationList, setStationList] = useState([]);
    const [nearestStationList, setNearestStationList] = useState([]);
    const searchLabel = "Wyszukaj stację kolejową (min. 3 znaki)";
    const isTextInserted = useMemo(() => userInput.trim().length >= 3, [userInput]);

    // Pobieranie listy stacji po załadowaniu strony
    useEffect(() => {
        setIsLoaded(false);
        getStations()
            .then(s => {
                setStations(s);
                setIsLoaded(true);
                if (!s.length) {
                    setErrorBannerText("Zwrócona z serwera lista stacji jest pusta");
                    setIsInputDisabled(true);
                }
            })
            .catch(err => {
                apiErrorHandler(err, navigate, setErrorBannerText)
            });
    }, [navigate, setErrorBannerText]);

    // Filtrowanie listy stacji po wprowadzeniu wartości przez użytkownika
    useEffect(() => {
        if (!isTextInserted || !isLoaded)
            return setStationList([]);

        const filteredStops = stations.filter(s => compareStrings(s.stop_name, userInput));
        setStationList(filteredStops);
    }, [userInput, stations, isLoaded, isTextInserted])

    // Wyszukiwanie najbliższych stacji
    useEffect(() => {
        // Brak danych stacji lub użytkownika lokalizacji
        if ((userPosition.x === 0 && userPosition.y === 0) || !isLoaded)
            return;

        setNearestStationList(getNearestStations(stations, userPosition));
    }, [userPosition, isLoaded, stations]);

    return (
        <Container>
            <Row className={"text-center"}>
                <span className={"h2"}>Zestawienie taborowe pociągów Kolei Mazowieckich</span>
            </Row>
            <Row>
                <img className={"mx-auto"} style={{width: "250px", maxWidth: "50%"}} src={logo} alt={"logo"}/>
            </Row>
            <Row className={"text-center mb-4"}>
                <span>Klimatyzowany Flirt czy EN57AKM z&nbsp;uchylnymi oknami? Skład piętrowy czy&nbsp;klasyczny? Sprawdź&nbsp;tutaj!</span>
            </Row>
            <Row className="justify-content-md-center">
                <Col md="8">
                    <InputGroup className={"mb-3"}>
                        <FloatingLabel label={searchLabel}>
                            <Form.Control
                                type="text"
                                placeholder={searchLabel}
                                value={userInput}
                                onChange={e => setUserInput(e.target.value)}
                                disabled={isInputDisabled}
                            />
                        </FloatingLabel>
                        <Button variant={"secondary"} onClick={() => getPosition(setUserPosition)}>
                            <Locate/>
                        </Button>
                    </InputGroup>

                    <ListGroup className={"mb-3"}>
                        {!isLoaded && isTextInserted && <LoadingSpinner text={"Ładowanie listy stacji..."}/>}
                        {
                            isLoaded && stationList.map((s) =>
                                <StationListElement key={s.stop_id} station={s} navigate={navigate}/>
                            )
                        }
                    </ListGroup>

                    <Form.Label visuallyHidden={!nearestStationList.length}>Najbliższe stacje</Form.Label>
                    <ListGroup className={"mb-3"}>
                        {nearestStationList.map((s) =>
                            <StationListElement key={s.stop_id} station={s} navigate={navigate} variant={"NEAREST"}/>
                        )}
                    </ListGroup>

                    <Form.Label visuallyHidden={!favouriteStationsList.length}>Ulubione stacje</Form.Label>
                    <ListGroup className={"mb-3"}>
                        {favouriteStationsList.map(s =>
                            <StationListElement key={s.stop_id} station={s} navigate={navigate}/>)}
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
}
