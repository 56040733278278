import {MdOutlineStar, MdOutlineStarBorder} from "react-icons/md";

/**
 * Ikona "gwiazdki" (lista ulubionych)
 * @param {boolean} isFavourite definiuje czy ikona jest zaznaczona
 * @returns {JSX.Element}
 * @constructor
 */
export default function Favourite({isFavourite}) {
    return isFavourite ? <MdOutlineStar/> : <MdOutlineStarBorder/>;
}
