import {Button} from "react-bootstrap";
import {MdOutlineStar, MdOutlineStarBorder} from "react-icons/md";
import {useMemo} from "react";
import useFavouriteStationsStorage from "../helpers/useFavouriteStationsStorage";
import Favourite from "./icons/Favourite";

/**
 * Przycisk dodawania stacji do ulubionych
 * @param {string|number} idStation ID stacji
 * @returns {JSX.Element}
 * @constructor
 */
export default function FavouriteButton({idStation}) {
    const [favouriteList, changeFavouriteState] = useFavouriteStationsStorage();

    const isFavourite = useMemo(() => favouriteList.has(idStation), [idStation, favouriteList]);

    return (
        <Button variant={"outline-dark"} onClick={() => changeFavouriteState(idStation)}>
            <Favourite isFavourite={isFavourite}/>
        </Button>
    )
}
