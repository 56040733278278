import {Card, Col, Container, Row} from "react-bootstrap";
import {Tabor} from "../helpers/Tabor";
import Klimatyzacja from "../elements/icons/Klimatyzacja";

/**
 * Strona z galerią taboru kolejowego
 * @returns {JSX.Element}
 * @constructor
 */
export default function SpisTaboru() {
    return (
        <Container>
            <Row className={"text-center"}>
                <span className={"h2"}>Park taborowy</span>
            </Row>
            <Row className={"mb-2 px-2"}>
                Poniżej znajduje się lista pojazdów wraz z&nbsp;oznaczeniami, które&nbsp;są&nbsp;używane
                w&nbsp;rozkładzie jazdy.
            </Row>
            <Row sm={2} md={4} lg={5} className={"g-2"}>
                {Tabor.getFullList().map(x => (
                    <Col key={x.nazwa}>
                        <Card>
                            <Card.Img variant={"top"} src={x.img} alt={x.nazwa}/>
                            <Card.Body>
                                <Card.Title>
                                    {x.nazwa}
                                    {x.source &&
                                        <sub className={"fs-6"}>
                                            [<a href={x.source} target={"_blank"}>Źródło zdjęcia</a>]
                                        </sub>}
                                </Card.Title>
                                <Card.Text>
                                    <Klimatyzacja
                                        is={x.klimatyzacja}/>&nbsp;{x.klimatyzacja ? `Pojazd klimatyzowany` : "Pojazd bez klimatyzacji"}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}
