import {Modal, Table} from "react-bootstrap";
import {useCallback, useMemo} from "react";
import {Chars} from "../enums/Chars";
import {Paths} from "../enums/Paths";
import LoadingSpinner from "./LoadingSpinner";

/**
 *
 * @param {trainAPI|null} selectedTrain
 * @param {function(trainAPI|null)} setSelectedTrain
 * @param {boolean} show
 * @param {function(boolean)} setShow
 * @param {function(string)} navigate
 * @returns {JSX.Element}
 */
export default function TrainDetailsModal({selectedTrain, setSelectedTrain, show, setShow, navigate}) {
    /** @type {string} */
    const headerText = useMemo(() => {
        if (!selectedTrain || !selectedTrain.trip)
            return "";
        else
            return `${selectedTrain.trip.isBUS ? "🚌" : ""}${Chars.NBSP}${selectedTrain.trip.linia}${Chars.NBSP}${selectedTrain.trip.nr_poc} ${Chars.ARROW_RIGHT}${Chars.NBSP}${selectedTrain.trip.kierunek}`;
    }, [selectedTrain]);
    /** @type boolean */
    const isLoading = useMemo(() => !selectedTrain || !selectedTrain.departures, [selectedTrain]);

    const handleHide = useCallback(() => {
        setShow(false);
        setSelectedTrain(null);
    }, [setSelectedTrain, setShow]);
    const handleClick = useCallback((id) => {
        navigate(Paths.STATION(id));
        handleHide();
    }, [handleHide, navigate]);

    return (
        <Modal centered={true} size={"lg"} show={show} onHide={handleHide}>
            <Modal.Header closeButton={true}>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={"trainDetails"}>
                {isLoading && <LoadingSpinner text={"Ładowanie trasy pociągu..."}/>}
                {
                    !isLoading &&
                    <Table className={"text-center"} hover={true} striped={true}>
                        <thead>
                        <tr>
                            <th>Stacja</th>
                            <th>Przyjazd</th>
                            <th>Odjazd</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            selectedTrain?.departures.map(d =>
                                <tr
                                    key={d.station_id}
                                    onClick={() => handleClick(d.station_id)}
                                >
                                    <td>{d.station_name}</td>
                                    <td>{d.przyjazd}</td>
                                    <td>{d.odjazd}</td>
                                </tr>)
                        }
                        </tbody>
                    </Table>
                }
            </Modal.Body>
        </Modal>
    );
}
