import useFavouriteStationsStorage from "../helpers/useFavouriteStationsStorage";

export default function Privacy() {
    const [, , usunUlubione] = useFavouriteStationsStorage();

    return <div className={"mx-2"}>
        <div className={"h2 text-center mb-4"}>Polityka prywatności</div>
        <div>
            <h3>Lokalizacja użytkownika</h3>
            Obliczanie odległości do najbliższej stacji odbywa&nbsp;się na&nbsp;urządzeniu użytkownika.
            Dane o&nbsp;lokalizacji nie&nbsp;są&nbsp;przekazywane na&nbsp;serwer.
            <h3>Dane w pamięci podręcznej</h3>
            Aplikacja wykorzystuje pamięć podręczną użytkownika do przechowywania następujących informacji:
            <ul>
                <li>lista ulubionych stacji
                    (<a href={"/"} onClick={() => usunUlubione()} variant={"link"} className={"p-0 text-danger"}>
                        usuń wszystkie ulubione stacje
                    </a>)
                </li>
                <li>ostatnio używana wersja aplikacji</li>
            </ul>
            Dane nie są wykorzystywane do profilowania użytkownika.
        </div>
    </div>
}
