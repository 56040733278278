/**
 * Porównuje dwa ciągi znakowe bez zwracania uwagi na wielkość znaków oraz wiodące białe znaki
 * @param {string} fullString Pełny ciąg znakowy
 * @param {string} partString Szukany fragment ciągu znakowego
 * @returns {boolean}
 */
export default function includeStrings(fullString, partString) {
    fullString = fullString.trim().toLocaleLowerCase();
    partString = partString.trim().toLocaleLowerCase();
    return fullString.includes(partString);
}
