import {Alert} from "react-bootstrap";
import {useMemo} from "react";

export default function ErrorBanner({errorText}) {
    const isVisible = useMemo(() => errorText !== "", [errorText]);

    return (
        <Alert variant={"danger"} show={isVisible} className={"p-2 mx-2"}>
            {errorText}
        </Alert>
    );
}
