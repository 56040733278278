import {useLocalStorage} from "usehooks-ts";
import {useCallback, useEffect, useState} from "react";
import packageJson from "../../package.json";
import {Modal} from "react-bootstrap";
import useChangelog from "../helpers/useChangelog";

// Obecna wersja aplikacji
const {version} = packageJson;

export default function ChangeModal() {
    const [show, setShow] = useState(false);
    const [lastVersion, setLastVersion,] = useLocalStorage("lastVersion", "");
    const [changelog, setChangelog] = useChangelog(lastVersion);

    const handleClose = useCallback(() => setShow(false), [setShow]);

    useEffect(() => {
        if (lastVersion !== version || lastVersion === "") {
            // Od czasu wizyty użytkownika zmieniła się wersja aplikacji
            // Nowy użytkownik (albo stary w nowej przeglądarce)
            if (lastVersion !== "") {
                setChangelog(lastVersion);
                setShow(true);
            }
            setLastVersion(version);
        }
    }, [lastVersion, setLastVersion, setChangelog, setShow]);

    return (
        <Modal show={show} onHide={handleClose} centered={true}>
            <Modal.Header closeButton={true}>
                <Modal.Title>Co nowego?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Od twojej ostatniej wizyty wprowadzone zostały następujące zmiany:
                <ul>
                    {
                        Object.keys(changelog).map(v => {
                            return changelog[v].map((zmiana, index) => (
                                <li key={v + " " + index}>{zmiana}</li>
                            ))
                        })
                    }
                </ul>
            </Modal.Body>
        </Modal>
    )
}
