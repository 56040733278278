import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import TrainDetailsModal from "../elements/TrainDetailsModal";
import getDepartures from "../apiCalls/getDepartures";
import apiErrorHandler from "../helpers/apiErrorHandler";
import getTrain from "../apiCalls/getTrain";
import LoadingSpinner from "../elements/LoadingSpinner";
import StationTable from "../elements/StationTable";

/**
 *
 * @param {function(string)} navigate
 * @param {function(string)} setErrorBannerText
 * @param {string} selectedDate
 * @returns {JSX.Element}
 */
export default function Station({navigate, setErrorBannerText, selectedDate}) {
    const {id} = useParams();
    const [departureList, setDepartureList] = useState(null);

    useEffect(() => {
        getDepartures(id, selectedDate)
            .then(resp => setDepartureList(resp))
            .catch(err => apiErrorHandler(err, navigate, setErrorBannerText));
    }, [id, selectedDate, navigate, setErrorBannerText]);

    // TrainDetailsModal
    const [selectedTrain, setSelectedTrain] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const handleShow = useCallback((selected) => {
        setSelectedTrain({trip: selected});
        setShowModal(true);
        getTrain(selected.trip_id)
            .then(resp => setSelectedTrain(resp))
            .catch(err => apiErrorHandler(err, navigate, setErrorBannerText));
    }, [navigate, setErrorBannerText]);

    useEffect(() => {
        const nextDeparture = document.getElementById("next");
        if (nextDeparture)
            nextDeparture.scrollIntoView({
                block: "center"
            });
    }, [departureList])

    return (
        <div>
            {!departureList && <LoadingSpinner text={"Ładowanie listy odjazdów..."}/>}
            {departureList &&
                <>
                    <div className={"h2 text-center"}>Rozkład jazdy<br/>{departureList.station.stop_name}</div>
                    <div>
                        <StationTable
                            departureList={departureList}
                            handleShowModal={handleShow}
                        />
                        <TrainDetailsModal
                            selectedTrain={selectedTrain}
                            setSelectedTrain={setSelectedTrain}
                            show={showModal}
                            setShow={setShowModal}
                            navigate={navigate}
                        />
                    </div>
                </>}
        </div>
    )
}
