import {getDistance} from "geolib";

/**
 * @typedef {{stationsAPI, distance: number}} stationsDistance
 */

/**
 * @type {number} Maksymalna odległość wyświetlanych stacji
 */
const MAX_DISTANCE = 10000;

/**
 *
 * @param {stationsAPI[]} stationsList
 * @param {CoordinateObject} userPosition
 * @returns stationsDistance[]
 */
export default function getNearestStations(stationsList, userPosition) {
    /**
     * @type {stationsDistance[]}
     */
    const toReturn = [];
    stationsList.forEach((curr) => {
        const dist = getDistance(userPosition, {longitude: curr.stop_lon, latitude: curr.stop_lat});
        if (dist <= MAX_DISTANCE)
            toReturn.push({...curr, distance: dist})
    });

    return toReturn.sort((a, b) => a.distance - b.distance).slice(0, 5);
}
