/**
 * @typedef {{lon: number, lat: number}} CoordinateObject Obiekt zawierający współrzędne
 */

/**
 * Zwraca domyślną lokalizację
 * @returns {CoordinateObject}
 */
export default function defaultPosition() {
    return {lon: 0, lat: 0};
}
