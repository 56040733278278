import en76 from "../../src/media/tabor/EN76.png";
import en57 from "../../src/media/tabor/EN57wKM.png";
import en57_2 from "../../src/media/tabor/EN57wKM_2.png";
import en57akm from "../../src/media/tabor/EN57AKMw1.png";
import en57akm2 from "../../src/media/tabor/EN57AKMw2.png";
import en57al from "../../src/media/tabor/EN57ALwKM.png";
import en71 from "../../src/media/tabor/EN71KM.png";
import er75 from "../../src/media/tabor/ER75.png";
import ew60 from "../../src/media/tabor/EW60.png";
import _45we from "../../src/media/tabor/45WEkm.png";
import er160 from "../../src/media/tabor/ER160.png";
import vt627 from "../../src/media/tabor/VT627.png";
import vt628 from "../../src/media/tabor/VT628.png";
import sn82 from "../../src/media/tabor/SN82.png";
import sn83 from "../../src/media/tabor/SN83.png";
import sa135 from "../../src/media/tabor/SA135.png";
import m222 from "../../src/media/tabor/222M.png";
import _111eb from "../../src/media/tabor/111Eb.png";
import sundeck from "../../src/media/tabor/P.png";
import sundeck_s from "../../src/media/tabor/Ps.png";
import eu47 from "../../src/media/tabor/EU47.png";
import bombardier from "../../src/media/tabor/B.png";
import bombardier_s from "../../src/media/tabor/Bs.png";

export class Tabor {

    /**
     * Zwraca listę wszystkich pojazdów z parku taborowego
     * @returns {Object<string, Tabor>}}
     * @static
     */
    static getAll() {
        return {
            EN57wKM: new Tabor(en57, "EN57wKM", false),
            EN57wKM_2: new Tabor(en57_2, "EN57wKM", false),
            EN71: new Tabor(en71, "EN71KM", false),
            EW60: new Tabor(ew60, "EW60", false),
            EN57AKMw1: new Tabor(en57akm, "EN57AKMw1", false),
            EN57AKMw2: new Tabor(en57akm2, "EN57AKMw1", true),
            EN57ALwKM: new Tabor(en57al, "EN57ALwKM"),
            ER75: new Tabor(er75, "ER75"),
            EN76: new Tabor(en76, "EN76"),
            "45WEkm": new Tabor(_45we, "45WEkm"),
            ER160: new Tabor(er160, "ER160"),
            VT627: new Tabor(vt627, "VT627", false),
            VT628: new Tabor(vt628, "VT628", false),
            SN82: new Tabor(sn82, "SN82", false),
            SN83: new Tabor(sn83, "SN83", false, "https://commons.wikimedia.org/wiki/File:Stiopa-Leon_foto.1.jpg"),
            SA135: new Tabor(sa135, "SA135"),
            "SA222": new Tabor(m222, "SA222"),
            "111Eb": new Tabor(_111eb, "111Eb", true, "https://commons.wikimedia.org/wiki/File:111Eb-001,_Przekazanie_KM_push-pulli_Pesy,_2016-01-13.jpg"),
            P: new Tabor(sundeck, "P", true, "https://commons.wikimedia.org/wiki/File:Wagon_PESA_Sundeck_typ_416B,_Koleje_Mazowieckie_(25119544422).jpg"),
            Ps: new Tabor(sundeck_s, "Ps", true, "https://commons.wikimedia.org/wiki/File:316B-002_(2),_Żmigród,_2015-12-05.jpg"),
            EU47: new Tabor(eu47, "EU47", true, "https://commons.wikimedia.org/wiki/File:Gdansk_Wrzeszcz_EU47_2.jpg"),
            B: new Tabor(bombardier, "B", true, "https://commons.wikimedia.org/wiki/File:2023-09-11_Bombardier_Twindexx_of_Koleje_Mazowieckie.jpg"),
            Bs: new Tabor(bombardier_s, "Bs", true, "https://commons.wikimedia.org/wiki/File:Bombardier,_Koleje_Mazowieckie_(15744283457).jpg"),
        };
    }


    /**
     * Zwraca listę wszystkich pojazdów z parku taborowego
     * @returns {Tabor[]}
     * @static
     */
    static getFullList() {
        const tabor = Tabor.getAll();
        return Object.keys(tabor).map(typ => tabor[typ]);
    }

    /**
     * Zwraca pojazd o określonej nazwie lub undefined, jeżeli pojazd nie znany
     * @param {string} name
     * @returns {undefined|Tabor}
     * @static
     */
    static getByName(name) {
        return Tabor.getAll()[name];
    }

    get img() {
        return this._img;
    }

    get nazwa() {
        return this._nazwa;
    }

    get klimatyzacja() {
        return this._klimatyzacja;
    }

    get source() {
        return this._source;
    }

    /**
     *
     * @param img
     * @param {string} nazwa Nazwa typu pojazdu (czytelna dla pasażera)
     * @param {boolean} klimatyzacja Definiuje czy pojazd jest wyposażony w klimatyzację
     * @param {string|null} source Adres URL źródła zdjęcia
     * @private
     */
    constructor(img, nazwa, klimatyzacja = true, source = null) {
        this._img = img;
        this._nazwa = nazwa;
        this._klimatyzacja = klimatyzacja;
        this._source = source;
    }
}
