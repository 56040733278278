import {useEffect, useState} from "react";

/**
 * Tworzy hook zawierający szerokość ekranu
 * @returns {{width: number, isSmall: boolean}}
 */
export default function useWindowWidth() {
    const MAX_SMALL_WIDTH = 767.98;

    const [windowWidth, setWindowWidth] = useState({
        width: window.innerWidth,
        isSmall: window.innerWidth <= MAX_SMALL_WIDTH,
    });

    useEffect(() => {
        const handleResize = () => setWindowWidth({
            width: window.innerWidth,
            isSmall: window.innerWidth <= MAX_SMALL_WIDTH,
        });

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth;
};
