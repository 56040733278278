import {Table} from "react-bootstrap";
import useWindowWidth from "../helpers/useWindowWidth";
import {useMemo} from "react";

/**
 * Zwraca rekord w tabeli
 * @param {departureDataAPI} departureData
 * @param {boolean} isSmall
 * @param {int} num_cols
 * @param {function(departureDataAPI)} handleShowModal
 * @returns {JSX.Element}
 * @constructor
 */
function TableElement({departureData, isSmall, num_cols, handleShowModal}) {
    const data = useMemo(() => {
        const przyj_odj = () => {
            const
                p = `p. ${departureData.przyjazd}`,
                o = `o. ${departureData.odjazd}`;
            if (departureData.przyjazd === "")
                return <>{o}</>;
            else if (departureData.odjazd === "")
                return <>{p}</>;
            else
                return <>{p}<br/>{o}</>;
        }

        return {
            nr_poc: (departureData.isBUS ? "🚌 " : "") + departureData.nr_poc,
            linia_kierunek: <>{departureData.linia}&nbsp;{departureData.kierunek}</>,
            przyjazd_odjazd: przyj_odj(),
            tabor: departureData.tabor,
        }
    }, [departureData]);

    if (isSmall)
        // MAŁY EKRAN
        return (
            <>
                <tr onClick={() => handleShowModal(departureData)}
                    id={departureData.is_next ? "next" : null}
                    className={departureData.is_next ? "next-departure" : "between-departures"}
                >
                    <td>{data.nr_poc}</td>
                    <td>{data.linia_kierunek}</td>
                    <td>{data.przyjazd_odjazd}</td>
                </tr>
                <tr onClick={() => handleShowModal(departureData)}>
                    {data.tabor === null ?
                        <td colSpan={num_cols}>
                            <i>{departureData.isBUS ? "Połączenie realizowane autobusem" : "Brak informacji o taborze"}</i>
                        </td> :
                        <>
                            <td colSpan={num_cols}>{data.tabor.pojazd} ({data.tabor.ilosc})</td>
                        </>
                    }
                </tr>
            </>
        );
    else
        // DUŻY EKRAN
        return (
            <tr onClick={() => handleShowModal(departureData)}>
                <td>{data.nr_poc}</td>
                <td>{data.linia_kierunek}</td>
                <td>{data.przyjazd_odjazd}</td>
                {data.tabor === null ?
                    <td colSpan={2}><i>{departureData.isBUS ? "Połączenie autobusowe" : "Brak informacji"}</i></td> :
                    <>
                        <td>{data.tabor.pojazd}</td>
                        <td>{data.tabor.ilosc}</td>
                    </>
                }
            </tr>
        );
}

/**
 * Wyświetla tabelę z listą odjazdów na stacji
 * @param {
    departuresAPI
} departureList
 * @param {

    function (departureDataAPI)

} handleShowModal
 * @returns {
    JSX.Element
}
 * @constructor
 */
export default function StationTable({
                                         departureList, handleShowModal
                                     }) {
    const windowWidth = useWindowWidth();
    const NUM_COLS = windowWidth.isSmall ? 3 : 5;

    return (
        <Table className={"text-center"} striped hover responsive>
            <thead>
            {windowWidth.isSmall &&
                <>
                    {/* MAŁY EKRAN */}
                    <tr>
                        <th>Nr pociągu</th>
                        <th>Kierunek</th>
                        <th>Przyjazd<br/>Odjazd</th>
                    </tr>
                    <tr>
                        <th colSpan={NUM_COLS}>Typ pojazdu (ilość)</th>
                    </tr>
                </>
            }
            {!windowWidth.isSmall &&
                <>
                    {/* DUŻY EKRAN */}
                    <tr>
                        <th rowSpan={2}>Nr&nbsp;pociągu</th>
                        <th rowSpan={2}>Kierunek</th>
                        <th>Przyjazd</th>
                        <th colSpan={2}>Tabor</th>
                    </tr>
                    <tr>
                        <th>Odjazd</th>
                        <th>Pojazd</th>
                        <th>Ilość</th>
                    </tr>
                </>
            }
            </thead>
            <tbody>
            {
                departureList.departures.length === 0 &&
                <tr>
                    <td colSpan={NUM_COLS}><i>Brak odjazdów</i></td>
                </tr>
            }
            {
                departureList.departures.map((d, k) => (
                    <TableElement
                        key={k}
                        departureData={d}
                        isSmall={windowWidth.isSmall}
                        num_cols={NUM_COLS}
                        handleShowModal={handleShowModal}
                    />
                ))
            }
            </tbody>
        </Table>
    )
}
