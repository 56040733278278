import axios from "axios";

/**
 * @typedef stationsAPI
 *
 * @property {string} stop_id ID stacji
 * @property {string} stop_name Nazwa stacji
 * @property {number} stop_lat Szerokość geograficzna (N/S)
 * @property {number} stop_lon Długość geograficzna (E/W)
 */

/**
 * Pobiera listę stacji kolejowych
 * @returns {Promise<stationsAPI[]>}
 */
export default function getStations() {
    return axios.get("/api/stations")
        .then(resp => resp.data);
}
