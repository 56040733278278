import useChangelog from "../helpers/useChangelog";
import {useLocalStorage} from "usehooks-ts";

export default function About() {
    const [changelog,] = useChangelog();
    const actualVersion = useLocalStorage("lastVersion", "");

    return (
        <div className={"mx-2"}>
            <div className={"h2 text-center mb-4"}>Informacje o projekcie</div>
            <div>
                <p>
                    KolMaz to niezależny projekt, który umożliwia uzyskanie informacji na temat taboru obsługującego
                    poszczególne połączenia Kolei Mazowieckich.
                    Rozkłady są generowane na podstawie plików GTFS dostępnych na stronie&nbsp;
                    <a href={"https://mkuran.pl"}>mkuran.pl</a>.
                    Dane o taborze pochodzą z zestawień prezentowanych&nbsp;
                    <a href={"https://www.mazowieckie.com.pl/pl/kategoria/rozklady-jazdy"}>na stronie przewoźnika</a>.
                </p>
                <p>
                    Aplikacja zawiera wyłącznie statyczny rozkład jazdy i nie dostarcza informacji o aktualnych
                    opóźnieniach w kursowaniu pociągów.
                </p>
                <p>Autorem aplikacji jest Dominik Pych.</p>
                <h3 className={"h3"}>Lista zmian</h3>
                <p>Aktualna wersja aplikacji: {actualVersion}</p>
                {
                    Object.keys(changelog).map(v => (
                        <div key={v}>
                            <h4>{v}</h4>
                            <ul>
                                {
                                    changelog[v].map((change, index) => (
                                        <li key={v + " " + index}>{change}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
